import React from "react"

import { BaseHeadProps, BaseHead, BaseProps, Base } from "../Base/Base"
import { SentryErrorBoundary } from "./ErrorBoundary"
import { WithUserProvider } from "./UserProvider"

export const WrappedHead: React.FunctionComponent<BaseHeadProps> = props => {
  return (
    <SentryErrorBoundary>
      <WithUserProvider>
        <BaseHead {...props} />
      </WithUserProvider>
    </SentryErrorBoundary>
  )
}

export const WrappedBase: React.FunctionComponent<BaseProps> = props => {
  return (
    <SentryErrorBoundary>
      <WithUserProvider>
        <Base {...props} />
      </WithUserProvider>
    </SentryErrorBoundary>
  )
}
