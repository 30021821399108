import React from "react"

import * as DesignKit from "design-kit"
import config from "../../config.json"
import styled from "@emotion/styled"

import {
  WrappedHead,
  WrappedBase,
} from "../shared-components/Shared/WrappedBase"

import logoImg from "../assets/shapes/logo_navy.svg"
import leftImg from "../assets/images/kamasutra/left.png"
import rightImg from "../assets/images/kamasutra/right.png"

const COLOR = "#16164D"
const BACKGROUND = "#FFF"

const Wrapper = styled.div`
  color: ${COLOR};
  background: ${BACKGROUND};
  font-family: "Work Sans", sans-serif;
`

const Image1 = styled.div`
  ${DesignKit.column({
    widthMobile: 4,
    widthTablet: 2,
    offsetTablet: 1,
    widthDesktop: 4,
    offsetDesktop: 2,
  })}
  padding-bottom: 24px;
  padding-left: 12%;
  padding-right: 12%;
  img {
    width: 100%;
  }
  ${DesignKit.breakpoints.tablet`
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  `}
`
const Image2 = styled.div`
  ${DesignKit.column({
    widthMobile: 4,
    widthTablet: 2,
    widthDesktop: 4,
  })}
  img {
    width: 100%;
  }
  padding-left: 12%;
  padding-right: 12%;
  img {
    width: 100%;
  }
  ${DesignKit.breakpoints.tablet`
  padding-left: 0;
  padding-right: 0;
  `}
`

const H1 = styled.h1`
  ${DesignKit.typographyStyles.headline2}
  text-transform: uppercase;
`

const BodyText = styled(DesignKit.Body)`
  text-align: center;
  ${DesignKit.breakpoints.tablet`
    font-size: 20px;
    line-height: 30px;
  `}
`

const Logo = styled.div`
  max-height: 100%;
  ${DesignKit.column({
    widthMobile: 2,
    offsetMobile: 1,
    widthTablet: 2,
    offsetTablet: 2,
    widthDesktop: 2,
    offsetDesktop: 5,
  })}
  text-align: center;
  user-select: none;
  img {
    width: 100%;
    ${DesignKit.breakpoints.tablet`
     width: 50%;
    `}
    ${DesignKit.breakpoints.desktop`
     width: 100%;
    `}
  }
`

const FullRow = styled.nav`
  ${DesignKit.column({
    widthMobile: 4,
    widthTablet: 4,
    offsetTablet: 1,
    widthDesktop: 8,
    offsetDesktop: 2,
  })}
`

const Center = styled.div`
  text-align: center;
`

interface SpacerProps {
  desktop: number
  mobile: number
}

const Spacer: React.FunctionComponent<SpacerProps> = styled.div`
  width: 100%;
  height: ${(props: SpacerProps) => props.mobile}px;
  ${(props: SpacerProps) => DesignKit.breakpoints.tablet`
  height: ${props.desktop}px;
  `}
`

const Home: React.FC = () => {
  return (
    <Wrapper>
      <Spacer mobile={24} desktop={36} />

      <DesignKit.GridRow>
        <Logo>
          <img src={logoImg} alt="Habito logo" />
        </Logo>
      </DesignKit.GridRow>
      <Spacer mobile={24} desktop={36} />
      <DesignKit.GridRow>
        <FullRow>
          <BodyText>
            Mortgage stress comes in many different forms: anxiety,
            sleeplessness… even loss of libido. Did you know that 1 in 10
            couples found getting a mortgage so stressful, they stopped being
            intimate with each other?
          </BodyText>
        </FullRow>
      </DesignKit.GridRow>
      <Spacer mobile={48} desktop={64} />
      <DesignKit.GridRow>
        <FullRow>
          <H1>
            <Center>Mortgage Kama Sutra</Center>
          </H1>
        </FullRow>
      </DesignKit.GridRow>
      <Spacer mobile={24} desktop={32} />
      <DesignKit.GridRow>
        <FullRow>
          <BodyText>
            <Center>
              13 beautiful illustrations inspired by mortgage jargon, created
              for us by graphic artist{" "}
              <a
                href="https://medium.com/hey-habito/raising-the-bar-making-mortgages-more-stimulating-8526a1cd7bda"
                target="_blank"
                rel="noopener noreferrer"
              >
                Noma Bar
              </a>
              . We hope they bring you a little fun and inspiration!
            </Center>
          </BodyText>
        </FullRow>
      </DesignKit.GridRow>
      <Spacer mobile={24} desktop={32} />

      <DesignKit.GridRow>
        <Image1>
          <img
            src={leftImg}
            alt="Incredibly witty references to Kamasutra positions but with mortgage jargon. ie. The base-rate scissor, Downpayment doggy."
          />
        </Image1>
        <Image2>
          <img
            src={rightImg}
            alt="More witty references to Kamasutra positions but with mortgage jargon. ie. Prime 69, The repayment rider."
          />
        </Image2>
      </DesignKit.GridRow>
      <Spacer mobile={64} desktop={96} />
      <DesignKit.GridRow>
        <FullRow>
          <DesignKit.BodySmall>
            <Center>
              Out of the 2,000 people we asked, more than 9% said they hadn't
              been intimate with their partner while applying for a mortgage.
              Let’s make that 0%, shall we? (OnePoll survey, September 2018)
            </Center>
          </DesignKit.BodySmall>
        </FullRow>
      </DesignKit.GridRow>
      <Spacer mobile={48} desktop={48} />
    </Wrapper>
  )
}

export const Head: React.FC = () => {
  return (
    <WrappedHead
      metaTitle="Mortgage Kama Sutra | Habito"
      metaDescription="13 beautiful illustrations inspired by mortgage jargon, created for us by graphic artist Noma Bar."
      canonicalUrl="https://www.habito.com/kamasutra"
      noIndex={false}
      pageName="homepage-kamasutra"
      intercom={false}
      config={config}
    />
  )
}

const Page: React.FC = () => {
  return (
    <WrappedBase>
      <Home />
    </WrappedBase>
  )
}

export default Page
